// Help Center Questions Slide Toggle

function isElementInViewport(elem) {
	var docViewTop = $(window).scrollTop();
	var docViewBottom = docViewTop + $(window).height();
	var elemTop = elem.offset().top;
	var elemBottom = elemTop + elem.height();

	return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

$(document).on(
	"click",
	".element-faq__questions .single .accordion",
	function () {
		if ($(this).parent().hasClass("active")) {
			$(".element-faq__questions .single").removeClass("active");
			$(".element-faq__questions .single").find(".answer").slideUp(250);
		} else {
			$(".element-faq__questions .single").removeClass("active");
			$(".element-faq__questions .single").find(".answer").slideUp(250);
			$(this).parent().addClass("active");
			$(this).parent().find(".answer").slideDown(250);
		}
	}
);

/*!
 * Process Timeline Element
 */

$(document).ready(function () {
	// animates progress bars in pager element
	$(".process-cycle").each(function () {
		var slideshow = $(this);
		var progress = $(this).parents(".container").find(".progress");

		slideshow.on("cycle-initialized cycle-before", function (e, opts) {
			progress.stop(true).css("width", 0);
		});

		slideshow.on("cycle-initialized cycle-after", function (e, opts) {
			if (!slideshow.is(".cycle-paused"))
				progress.animate({ width: "100%" }, opts.timeout, "linear");
		});

		slideshow.on("cycle-paused", function (e, opts) {
			progress.stop();
		});

		slideshow.on("cycle-resumed", function (e, opts, timeoutRemaining) {
			progress.animate({ width: "100%" }, timeoutRemaining, "linear");
		});
	});

	// finds biggest element and adapts rest to it
	function heightEqualizing() {
		$(".process-cycle").each(function () {
			var element = $(this).find(".element-process__single");

			var elementHeights = element
				.map(function () {
					return $(this).height();
				})
				.get();

			var maxHeight = Math.max.apply(null, elementHeights);

			element.height(maxHeight);
		});
	}

	heightEqualizing();

	// intitialize slider
	function initialize_cycle() {
		if ($(window).width() > 767) {
			$(".process-cycle").each(function () {
				$(this).cycle();
			});
		} else {
			$(".process-cycle").each(function () {
				$(this).cycle("destroy");
			});
		}
	}

	initialize_cycle();

	$(".process-cycle").each(function () {
		$(this).cycle("pause");
	});

	function progress_width() {
		$(".element-process__pager").each(function () {
			var element_count = $(this).find(".single").length;
			var element_width = $(this).find(".single").width();
			var container_width = $(this).width();
			var element_padding = element_width - 64;
			var gap_width =
				(container_width - element_count * element_width) /
				(element_count - 1);
			var progress_width = element_padding + gap_width;
			$(this).find(".progress-wrap").css("width", progress_width);
		});
	}

	progress_width();

	$(window).resize(function () {
		progress_width();
		initialize_cycle();
	});
});

$(window).scroll(function () {
	$(".process-cycle").each(function () {
		var element_position = $(this).offset().top;
		var topOfWindow = $(window).scrollTop();

		if (element_position < topOfWindow + $(window).height() - 150) {
			// alert('sad');
			$(".process-cycle").cycle("resume");
		}
	});
});

//
/*!
 * Features Element Photo Click
 */

$(".element-features .image").click(function () {
	var fullSize = $(this).attr("data-full");
	var img = '<img src="' + fullSize + '">';
	$(".photoPopup").fadeIn();
	$(".photoPopup .photo").html(img);
	setTimeout(function () {
		$(".photoPopup .imgWrap").addClass("active");
	}, 350);
});

// about us team members

// filter

$(".aboutus_team__filter button").click(function () {
	var category = $(this).attr("data-category");

	$(".aboutus_team__filter button").removeClass("active");
	$(this).addClass("active");

	$(".aboutus_team__members").removeClass("active");
	$('.aboutus_team__members[data-category="' + category + '"]').addClass(
		"active"
	);

	$(".aboutus_team__more .chBtn").remove();
	$(".aboutus_team__members").find(".single-member").show();
});

$(".aboutus_team__more .chBtn").click(function () {
	$(this).remove();
	$(".aboutus_team__members").find(".single-member").show();
});

// Gallery Element
$(document).ready(function () {
	// Activates first button in Gallery element nav
	$(".element-gallery .navigation .ch-tag-button:first-child").removeClass(
		"weak"
	);

	// Activates first block in Gallery element
	$(".element-gallery .blocks > div:first-child").show();

	$(".element-gallery .navigation .ch-tag-button").click(function () {
		var i = $(this).index();

		$(".element-gallery .navigation .ch-tag-button").addClass("weak");
		$(this).removeClass("weak");

		$(".element-gallery .blocks > div").hide();
		$(".element-gallery .blocks > div").eq(i).show();
	});

	$(".element-gallery .navigation .ch-tag-button:not(#nav-video)").click(
		function () {
			setTimeout(function () {
				$(".cycle-slide").each(function () {
					var src = $(this).find("iframe").attr("src");
					$(this).find("iframe").attr("src", null);
					$(this).find("iframe").attr("src", src);
				});
			}, 100);
		}
	);

	$("#video-prev, #video-next").click(function () {
		setTimeout(function () {
			$(".cycle-slide").each(function () {
				if (!$(this).hasClass("cycle-slide-active")) {
					var src = $(this).find("iframe").attr("src");
					$(this).find("iframe").attr("src", null);
					$(this).find("iframe").attr("src", src);
				}
			});
		}, 100);
	});
});

// George Element
$(".element-george-table tbody .tooltip").each(function () {
	var tooltip = $(this);
	var tooltipContent = $(this).html();
	$(tooltip)
		.parents("td")
		.find(".tooltip-trigger")
		.append(tooltip)
		.append(
			'<span class="tooltip"><span class="wrp">' +
				tooltipContent +
				"</span></span>"
		);
	$(tooltip).remove();
});

// George The Second Accordion
$(document).on("click", ".card-secondary .accordion-trigger", function () {
	const trigger = $(this);
	const group = $(this).parent();
	const card = $(this).parents(".card-secondary");

	const dflTitle = card.find(".updateableBtn").attr("data-default-title");
	const dflUrl = card.find(".updateableBtn").attr("data-default-url");

	if (group.attr("aria-expanded") === "true") {
		$(".accordion-list li").attr("aria-expanded", "false");
		$(".accordion-list .content").slideUp(250);

		card.find(".updateableBtn").html(dflTitle).attr("href", dflUrl);
	} else {
		$(".accordion-list li").attr("aria-expanded", "false");
		$(".accordion-list .content").slideUp(250);
		group.attr("aria-expanded", "true");

		if (trigger.hasClass("expandable")) {
			group.find(".content").slideDown(250);
		}

		var ovrdUrl = group.attr("data-btn-url")
			? group.attr("data-btn-url")
			: dflUrl;
		var ovrdTitle = group.attr("data-btn-title")
			? group.attr("data-btn-title")
			: dflTitle;

		card.find(".updateableBtn").html(ovrdTitle).attr("href", ovrdUrl);
	}
});

$(document).ready(function () {
	$(".element-george-cards").each(function () {
		var h = 0;
		var target;

		$(this)
			.find(".card-secondary")
			.each(function () {
				if ($(this).outerHeight() > h) {
					var h = $(this).outerHeight();
					var target = $(this);
				}
			});

		$(target).css("align-self", "flex-start");
	});
});

// George Table Accordion
$(document).ready(function () {
	$(".element-george").each(function () {
		var table = $(this).find(".element-george-table").html();

		$(this)
			.find(".element-george-table-cards .card")
			.each(function (i) {
				const card = $(this);
				const rowNo = i + 2;

				card.append(table);
				card.find("table thead").remove();
				card.find(
					"table tr td:not(:nth-child(1), :nth-child(" + rowNo + "))"
				).remove();

				// remove empty cell
				card.find("table a")
					.parents("tr")
					.find("td:first-child")
					.remove();
				// span button cell to both rows
				card.find("table a").parents("td").attr("colspan", 2);
			});

		$(this)
			.find(".element-george-table-cards .card .trigger")
			.click(function () {
				$(this).toggleClass("active");
				$(this)
					.parents(".card")
					.find(".george-table-style")
					.toggleClass("active");
			});
	});
});

// hero form
$(".hero-form .form-trigger").click(function () {
	$(this).hide();
	$(".hero-form .form").show();
});

$(".scrollMath .scrollMeDown").click(function () {
	var hero = $(".scrollMath");

	$([document.documentElement, document.body]).animate(
		{
			scrollTop: hero.offset().top + hero.height(),
		},
		1000
	);
});

// honepoy
// Typed hero variation / honeypot hero

$(".honeypot .switcher button").click(function () {
	const transition = 100;
	var tab = $(this).attr("data-tab");

	$(".honeypot .switcher button").removeClass("active");
	$(this).addClass("active");

	var blabla = $('.honeypot .tab[data-tab="' + tab + '"]').outerHeight();
	$(".honeypot .fake-bg").css("min-height", blabla);

	$(".honeypot .tab").fadeOut(transition);
	setTimeout(function () {
		$('.honeypot .tab[data-tab="' + tab + '"]').fadeIn(transition);
	}, transition + 25);
});

$(document).ready(function () {
	if ($(".sticky-george").length) {
		const el = document.querySelector(".sticky-george");
		const observer = new IntersectionObserver(
			([e]) =>
				e.target.classList.toggle("stuck", e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		observer.observe(el);
	}
});

$(".element-hero-2 .tab-switcher button").click(function () {
	clicked = $(this);
	i = clicked.index();
	nth = i + 2; // adding 2 to i since tabs are in same paretn as .tab-switcher

	clicked.parent().find("button").removeAttr("data-active");
	clicked.attr("data-active", "");

	clicked.parents(".widget").find(".tab").hide();
	clicked
		.parents(".widget")
		.find(".tab:nth-of-type(" + nth + ")")
		.show();
});

$('.element-hero-2-pill .btns a[href="#widget"]').click(function () {
	container = $(this).parents(".element-hero-2-pill");
	container.find("#price-estimator").show();
	container.find(".backbtn").show();
	container.find(".btns").hide();
});

$(".element-hero-2-pill .backbtn").click(function () {
	container = $(this).parents(".element-hero-2-pill");
	container.find("#price-estimator").hide();
	container.find(".backbtn").hide();
	container.find(".btns").show();
});

// temporary event form, delete after 2023
$(document).ready(function () {
	if ($(".element-temp-eventform").length) {
		var scriptUrl =
			"https://script.google.com/macros/s/AKfycbxNnyv5B5ZBUFmDDIFtbYyIP_BMAg1iS8CN2-fhRiRRJATi8yBvXzUJhlW9lTH_6drp/exec";

		$.ajax({
			url: scriptUrl,
			type: "GET",
			dataType: "json",
			success: function (response) {
				var cellValue = response.value; // Extract the value
				console.log(cellValue);

				if (cellValue < 60) {
					$(".element-temp-eventform .loading").remove();
					$(".element-temp-eventform form").show();
				} else {
					$(".element-temp-eventform").html(
						'<p class="message">Es tut uns leid. Da der Event bereits vollständig ausgebucht ist, können wir keine weitere Anmeldungen annehmen.</p>'
					);
				}
			},
		});
	}
});

$(document).on("submit", "form.eventlimitform", function (e) {
	e.preventDefault();

	const form = $(this),
		sheetName = form.attr("data-gsheet"),
		success = form.attr("data-success"),
		formData = form.serialize() + "&sheetName=" + sheetName,
		submitButton = form.find('button[type="submit"]');

	submitButton.prop("disabled", true).addClass("loading");

	const jqxhr = $.ajax({
		url: "https://script.google.com/macros/s/AKfycbzmX5pAvKjcgkIOMW2minsJFA19gnQv4zZSBimcCr8i7uuwIO8XlQkNIPZd7Wbh8L2_/exec",
		method: "GET",
		dataType: "json",
		data: formData,
	})
		.done(function (data) {
			if (data.G1Value <= 60) {
				$("form.eventlimitform").html(
					// success
					'<p class="message">Ihre Anmeldung war erfolgreich. Wir freuen uns auf einen magischen Abend mit Ihnen.</p>'
				);
			} else {
				$("form.eventlimitform").html(
					// someone already signed up
					'<p class="message">Es tut uns leid. Der Event ist in der Zwischenzeit vollständig ausgebucht und wir können keine weiteren Anmeldungen mehr annehmen.</p>'
				);
			}
		})
		.fail(function (xhr, status, error) {
			console.log("Error:", error);
		});
});
